import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "D:/DEV02/Progetti/torredienna_web/src/components/layout.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShare, faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import SEO from "../components/seo.js";
import * as Container from "../components/container.js";
import * as Hero from "../components/hero.js";
import * as Section from "../components/splitsections.js";
import { Button } from "../components/button.js";
import Card from "../components/card.js";
import Anchor from "../components/anchor.js";
import Title from "../components/title.js";
import { Prezzo, Sconto, Disponibile, Spedizione } from "../components/bookDetails.js";
import { Bio, Col } from "../components/bio.js";
import * as Form from "../components/form.js";
import Phone from "../components/phone.js";
import Player from "../components/audioplayer.js";
import Citazione from "../components/citazione.js";
import { Footer, Copyright, Patamu } from "../components/footer.js";
import { ReadMore } from "../components/readMore";
import ImageGallery from "../components/imageGallery";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEO mdxType="SEO" />
    <Hero.FullWidth id="ID_Hero" background="#f2ba4d" customH1 h2="Antico Osservatorio Astronomico e Ombelico della Sicilia e della Trinacria: Storia, Archeologia, Topografia e Archeoastronomia" textColor="#2c2e31" imageSlider />
    <Container.Fluid id="progetto" className={"pt-4 pb-2"} background={"#ebe7db"} color={"#2c2e31"}>
      <Section.FullWidth>
        <Title type={"h3"} mdxType="Title">Il Progetto di Ricerca</Title>
        <hr></hr>
      </Section.FullWidth>
      <Section.One className={"pr-lg-0"}>
  <header style={{
          "textAlign": "center",
          "marginBottom": "20px"
        }}>
    <h1 style={{
            "fontWeight": "bold",
            "fontSize": "1.8rem"
          }}>Il Progetto di Ricerca sulla Torre Ottagonale di Enna</h1>
  </header>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Il nostro <strong>progetto di ricerca</strong> è incentrato sullo studio della <strong>Torre Ottagonale di Enna</strong> al fine di indagare le sue <strong>misteriose origini</strong>. Ci siamo avvalsi di un approccio di analisi multidisciplinare che integra <strong>storia, topografia, archeologia, archeoastronomia ed informatica</strong>. Gli interrogativi principali che ci siamo posti all’inizio della nostra ricerca sono:
  </p>
  <ul style={{
          "margin": "20px 0",
          "paddingLeft": "20px"
        }}>
    <li style={{
            "marginBottom": "10px"
          }}><strong>Chi ha costruito la Torre Ottagonale?</strong></li>
    <li style={{
            "marginBottom": "10px"
          }}><strong>Quali sono le sue funzioni originarie?</strong></li>
    <li style={{
            "marginBottom": "10px"
          }}><strong>Perché Enna è stata scelta come sito</strong> per la fondazione di questo magnifico edificio?</li>
  </ul>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Attraverso la rilettura delle fonti antiche, la scoperta di documenti inediti ed il riconoscimento di alcuni reperti archeologici, gli autori della ricerca fanno luce sulle innumerevoli contraddizioni che avvolgono il monumento più enigmatico della Sicilia: la <strong>Torre Ottagonale</strong>. Un innovativo processo di analisi multidisciplinare, che coinvolge <strong>storici, topografi, archeologi e archeoastronomi</strong>, rivela per la prima volta le <strong>origini</strong> e tutti i <strong>segreti</strong> legati alla fondazione e alla reale funzione dell’edificio, indietro fino all’<strong>età del Bronzo</strong>.
  </p>
  <blockquote style={{
          "margin": "20px 0",
          "padding": "10px 20px",
          "borderLeft": "5px solid #ccc",
          "background": "#f9f9f9"
        }}>
    <p style={{
            "fontStyle": "italic",
            "lineHeight": "1.6"
          }}>
      Sul finire del II millennio a.C., una figura sacerdotale di matrice culturale italica, in possesso di competenze divinatorie ed ingegneristiche straordinarie, avrebbe condotto i <strong>Siculi</strong> in Sicilia respingendo i <strong>Sicani</strong>, impadronendosi dell’altopiano di Enna e fondando il più importante <em>Templum Caeli</em> del mondo antico. Questo spazio cosmico idealmente riprodotto sulla terra corrisponderebbe a quello che le fonti greche e latine citano come <strong>Umbilicus Siciliae</strong>, l’ombelico della Sicilia e della Trinacria.
    </p>
  </blockquote>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Così come a Roma, anche ad Enna l’<em>umbilicus</em>, collocato sulla sommità dell’<em>arx auguraculum</em>, segnava l’origine degli assi ortogonali (<em>cardo</em> e <em>decumano</em>) alla base dell’impianto urbanistico della città (<em>umbilicus urbis</em>) al momento della sua fondazione. La <strong>Torre Ottagonale</strong> corrisponde pertanto al <em>mundus</em>, una sorta di porta spazio-temporale di collegamento metafisico tra il <strong>cielo</strong>, la <strong>terra</strong> e il <strong>sottosuolo</strong>: un sito privilegiato per il pieno controllo sul promontorio di Enna da cui ha origine il sistema di ripartizione fondiaria delle campagne circostanti, ma soprattutto il migliore punto di stazionamento per l’<strong>osservazione degli astri</strong> nel cielo notturno fino all’orizzonte.
  </p>
      </Section.One>
      <Section.Two className={"py-3 py-lg-0"} style={{
        alignSelf: "start!important"
      }}>
  <header style={{
          "textAlign": "center",
          "marginBottom": "20px"
        }}>
    <h2 style={{
            "fontWeight": "bold",
            "fontSize": "1.5rem"
          }}>La Rilevanza del Progetto</h2>
  </header>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Il nostro progetto di ricerca ambisce all’<strong>opportunità straordinaria</strong> di comprendere le dinamiche di <strong>ibridazione sociale, culturale, politica ed economica</strong> che coinvolsero le antiche comunità siciliane - tra cui <strong>Sicani, Siculi ed Elimi</strong> - che prosperarono in Sicilia durante l’<strong>età del Bronzo</strong>.
  </p>
  <blockquote style={{
          "margin": "20px 0",
          "padding": "10px 20px",
          "borderLeft": "5px solid #ccc",
          "background": "#f9f9f9"
        }}>
    <p style={{
            "fontStyle": "italic",
            "lineHeight": "1.6"
          }}>
      La perfetta sovrapposizione della <strong>costellazione del Capricorno</strong> - visibile nell’emisfero boreale fino a circa il secolo XI a.C. - alla topografia urbana della città di Enna costituisce la prova inequivocabile di un deliberato atto di progettazione urbanistica dettato dall’osservazione astronomica secondo l’<em>Etrusca Disciplina</em>. Questo ci consente di risalire alla presunta data di fondazione del più antico impianto urbano sull’altopiano ennese.
    </p>
  </blockquote>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    La città di <strong>Enna</strong> ospiterebbe così nel cuore della Sicilia il più antico sito del Mediterraneo per l’<strong>osservazione astronomica</strong>, la <strong>divinazione</strong> e l’<strong>agrimensura</strong>, riconducibile alla messa in pratica di antichissime conoscenze e tecniche documentate nelle città etrusche di <strong>Marzabotto, Felsina, Spina, Tarquinia, Volsinii</strong>, e altre ancora, che testimoniano influenze culturali provenienti dall’area egeo-anatolica e che puntualmente hanno riscontro anche in alcune città ortogenetiche dell’Umbria e del Lazio, tra cui <strong>Perugia, Spoleto, Anagni, Arpino, Ferentino</strong>, ma soprattutto <strong>Alatri</strong>.
  </p>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Noi autori di questa ricerca vogliamo far luce sulla <strong>Storia obliata di Henna</strong> ed introdurne un <strong>nuovo capitolo</strong> attraverso la rilettura delle <strong>fonti antiche</strong>, la scoperta di <strong>documenti inediti</strong> ed il riconoscimento di alcuni reperti archeologici. Condividiamo con entusiasmo le nostre <strong>scoperte</strong> con il lettore, con l'obiettivo di fornire nuove interpretazioni di eventi, manufatti e monumenti e così raggiungere nuove prospettive di ricerca future.
  </p>
  <p style={{
          "lineHeight": "1.6",
          "fontSize": "1rem"
        }}>
    Il nostro progetto di ricerca sulla <strong>Torre Ottagonale di Enna</strong> costituisce un concreto contributo intellettuale e scientifico per sanare l’<strong>ingiustizia</strong> a cui questo monumento e l’intera città di <strong>Enna</strong> sono sottoposti da tempo. Tutto ciò dimostra come sia possibile, attraverso la collaborazione tra professionisti indipendenti, dare impulso alla valorizzazione e al riconoscimento della città di Enna come <strong>Patrimonio Mondiale dell'Umanità</strong>.
  </p>
        <div style={{
          "textAlign": "center",
          "marginTop": "30px"
        }}>
  <h3 style={{
            "fontWeight": "bold",
            "fontSize": "1.2rem"
          }}>Collaborazioni</h3>
  <ul style={{
            "listStyle": "none",
            "padding": "0"
          }}>
    <li style={{
              "marginBottom": "10px"
            }}>
      <a href="https://ecoastronomy.edu.lk/" target="_blank" style={{
                "textDecoration": "none",
                "color": "#007bff",
                "fontWeight": "bold"
              }}>
        Eco Astronomy Sri Lanka
      </a> 
      (fondato nel 2018)
    </li>
    <li style={{
              "marginBottom": "10px",
              "color": "#555",
              "fontStyle": "italic"
            }}>
      Altre organizzazioni potranno unirsi in futuro per arricchire il progetto
    </li>
  </ul>
        </div>
      </Section.Two>
      <Container.Fixed id="update" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Section.FullWidth className="text-center mt-4">
  <Title type={"h3"} mdxType="Title">Aggiornamento Continuo</Title>
          <hr></hr>
  <div className="sostenitori-container mx-auto px-8 py-12" style={{
            maxWidth: '900px',
            backgroundColor: '#fff5f5',
            borderRadius: '16px',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}>
    <p className="font-semibold text-lg mb-6 leading-relaxed" style={{
              color: '#991b1b',
              fontSize: '1.2rem',
              margin: '20px 16px 0'
            }}>
      Scopri la lista sempre aggiornata di coloro che, con i <strong>fatti</strong>, hanno sostenuto il nostro <strong>progetto di ricerca</strong>, dimostrando il loro <strong>livello culturale</strong>, <strong>passione</strong> e <strong>impegno</strong> per riscrivere insieme una nuova pagina della <strong>storia di Enna</strong> e della <strong>Sicilia</strong>!
    </p>
    <div className="d-flex justify-content-center mt-6 w-100 align-items-center">
      <Button solid inline targetBlank color="#db4437" href="https://www.torredienna.it/redirect/sostenitori-intellettuali-storia-torre-ottagonale-enna.html" className="mt-3 mb-1 px-4 py-2 font-medium" style={{
                fontSize: '0.9rem',
                borderRadius: '8px'
              }} mdxType="Button">
        Consulta la Lista dei Sostenitori
      </Button>
    </div>
  </div>
  <br />
  <br />
  <div className="aggiornamenti-container mx-auto px-8 py-12 mt-8" style={{
            maxWidth: '900px',
            backgroundColor: '#e0f2fe',
            borderRadius: '16px',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}>
    <p className="font-semibold text-lg mb-6 leading-relaxed" style={{
              color: '#1d4ed8',
              fontSize: '1.2rem',
              margin: '20px 16px 0'
            }}>
Se hai già acquistato il libro, rimani aggiornato sui <strong>progressi</strong> del nostro <strong>progetto</strong> e scopri tutte le <strong>novità</strong> più recenti!
    </p>
    <div className="d-flex justify-content-center mt-6 w-100 align-items-center">
      <Button solid inline targetBlank color="#4285F4" href="https://www.torredienna.it/redirect/registro-cambiamenti-evolutivi-torre-ottagonale-enna.html" className="mt-3 mb-1 px-4 py-2 font-medium" style={{
                fontSize: '0.9rem',
                borderRadius: '8px'
              }} mdxType="Button">
        Consulta gli Ultimi Aggiornamenti
      </Button>
    </div>
  </div>
        </Section.FullWidth>
      </Container.Fixed>
      <Container.Fixed id="compra" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Title type="h3" as="h2" className="py-4 px-3" style={{
          textAlign: "center",
          width: "100%",
          color: "#000000"
        }} mdxType="Title">
Acquista il libro per scoprire l'approfondito<br />
e inedito studio sulla Torre Ottagonale di Enna<br />
con tutti i dettagli sulla sua storia e<br />
sull'antica fondazione di Enna, risalente a 3500 anni fa
        </Title>
        <Section.FullWidth className={"py-5"} style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          flexWrap: "wrap"
        }}>
          <div style={{
            "flex": "0 0 500px",
            "textAlign": "left"
          }}>
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "651px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "141.92307692307693%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgT/2gAMAwEAAhADEAAAAdWtWUjBnlxXJozaUxwD/8QAHBAAAgIDAQEAAAAAAAAAAAAAAAECAxESExQh/9oACAEBAAEFAomJ5bWYSarrkWfZbtrZI6yRwrOFR56j/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAECEhH/2gAIAQMBAT8BizrDL//EABcRAQADAAAAAAAAAAAAAAAAAAABERP/2gAIAQIBAT8BpbWGsP/EABwQAQACAQUAAAAAAAAAAAAAAAAhMgEQETFBsf/aAAgBAQAGPwJOYcs6zsj10qoo/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFhQXGB8f/aAAgBAQABPyGiY8+YIb23Fw2gQGbwyMHyJdqFBk9yvoj9GY1Wvc6ks5DP/9oADAMBAAIAAwAAABDvHgH/xAAaEQACAgMAAAAAAAAAAAAAAAAAARAhMeHw/9oACAEDAQE/EHZG0e3o/8QAGxEAAgEFAAAAAAAAAAAAAAAAAAEREGFxgfD/2gAIAQIBAT8QcWoozFl9s//EAB8QAQACAgICAwAAAAAAAAAAAAEAESExQYFR0WGRsf/aAAgBAQABPxAL4GAxwNtQOtcalaQ3S1EOs9krFSqo0l/IhV8WRF5Im4xodfIgmihvMgUBpVvcW2vb7jSw2C1cfc//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "UMBILICUS SICILIAE ET TRINAKRIAE La Torre Ottagonale di Enna",
                  "title": "UMBILICUS SICILIAE ET TRINAKRIAE La Torre Ottagonale di Enna",
                  "src": "/static/7520b51be084b39795ecb1ecf4164e52/aeb8d/getCoverISBN.jpg",
                  "srcSet": ["/static/7520b51be084b39795ecb1ecf4164e52/ab8e0/getCoverISBN.jpg 260w", "/static/7520b51be084b39795ecb1ecf4164e52/ddb38/getCoverISBN.jpg 520w", "/static/7520b51be084b39795ecb1ecf4164e52/aeb8d/getCoverISBN.jpg 651w"],
                  "sizes": "(max-width: 651px) 100vw, 651px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span></p>
          </div>
          <div style={{
            textAlign: "center",
            padding: "5px",
            backgroundColor: "#f8f9fa",
            borderRadius: "12px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
          }}>
  <p style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "20px",
              color: "#333"
            }}>
    <strong>Umbilicus Siciliae et Trinakriae <br />La Torre Ottagonale di Enna <br /> <br />Storia, archeologia, topografia e archeoastronomia</strong>
  </p>
  <ul style={{
              fontSize: "1rem",
              lineHeight: "1.8",
              marginBottom: "30px",
              textAlign: "left",
              maxWidth: "600px",
              margin: "0 auto",
              color: "#555"
            }}>
    <li><strong>Anno pubblicazione:</strong> 2024</li>
    <li><strong>ISBN:</strong> 9791221063011</li>
    <li><strong>Edizione:</strong> Scientifica, copertina rigida, formato A4</li>
    <li><strong>Pagine:</strong> 360 con immagini e tavole a colori</li>
  </ul>
  <br />
  <Button solid inline large targetBlank color={"#db4437"} href="https://torredienna.it/salespage/index.html#offerte" className={"mt-3 px-4 py-2"} style={{
              fontSize: "1.1rem",
              borderRadius: "8px",
              fontWeight: "bold"
            }} mdxType="Button">
Sì, lo voglio acquistare
  </Button>
          </div>
        </Section.FullWidth>
      </Container.Fixed>
      <Section.FullWidth>
        <br />
        <br />
        <br />
        <Title type={"h3"} mdxType="Title">Gli autori del progetto di ricerca</Title>
        <hr></hr>
      </Section.FullWidth>
      <Container.Fixed id="autori" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="PaoloDangelo.jpg" alt="Paolo D’angelo" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Paolo D’angelo (Ennese)</b></div>
            <div className={"pb-2"}><b><i>Archeologo e topografo specializzato in topografia antica</i></b> svolge attività di ricerca come libero professionista iscritto all'Elenco del MiC dei 'Professionisti dei Beni Culturali'.<br /> <br /> <b>Fondatore di ArcheoSIT</b></div>
            <ul>
              <li><b>Docente</b> del <b>MiUR</b> di storia dell'arte</li>
              <li><b>Presidente</b> della <b>Sezione</b> provinciale di <b>Enna</b> dell'<b>Associazione Italiana Insegnanti di Geografia</b></li>
              <li>Membro della <b>Società Ennese di Storia Patria</b></li>
              <li><b>Consulente</b> tecnico d'ufficio del <b>Tribunale di Enna</b></li>
              <li><b>Autore e copywriter</b> di contenuti archeologici per il web e riviste</li>
            </ul>
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="FabrizioSeverino.jpg" alt="Fabrizio Natanaele Severino" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Fabrizio Natanaele Severino (Ennese)</b></div>
            <div className={"pb-2"}><b><i>Informatico e problem solver con comprovata esperienza</i></b> svolge consulenze tecnico-strategiche per imprenditori, manager e professionisti. <br /> <br /><b>Fondatore di Zenit Security</b>, un'attività professionale specializzata nella risoluzione di problemi difficili e complessi.</div>
            <ul>
              <li><b>Progettazione e sviluppo di sistemi informatici su misura, anche per il lavoro remoto</b></li>
              <li><b>Integrazione di nuovi sistemi con quelli esistenti</b></li>
              <li><b>Automazione di attività operative e ripetitive</b></li>
              <li><b>Manutenzione e monitoraggio proattivo dei sistemi</b></li>
              <li><b>Gestione della conoscenza e protezione dei dati</b></li>
            </ul>
            <div className={"pb-2"}>Il tutto garantendo una <b>sicurezza informatica</b> adatta alle specifiche esigenze dei clienti. <br />  <br />La mia visione consiste nel trasformare il maggior numero possibile di problemi in opportunità di miglioramento, apprendimento e innovazione, facendo leva su modelli mentali e processi di risoluzione sempre più agili.</div>
            <div className={"pb-2"}>Il digitale è quindi un’estensione delle nostre capacità, perché la “tecnologia” più potente in assoluto è da sempre l’essere umano in grado di collaborare per creare valore reciproco. <br />  <br /> Per me, l’innovazione è la capacità di combinare e fondere idee consolidate ed emergenti, di individuare relazioni tra concetti apparentemente distanti per creare nuove connessioni, e di estrarre l’essenza di un concetto per adattarla a contesti diversi. Questo processo permette di dare vita a nuove soluzioni, risolvere problemi specifici e superare lo status quo.</div>
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed id="severino" className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="AngeloSeverino.jpg" alt="Angelo Severino" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Angelo Severino (Ennese)</b></div>
            <div className={"pb-2 h1"}><b>(n. 1947, m. 2022)</b></div>
            <b>Giornalista investigativo, pubblicista e storico.</b> Ha collaborato con 'La Sicilia' e la 'Gazzetta del Sud'. Ha condotto numerose inchieste investigative e ricerche storiche sulla città di Enna, tra le più rinomate ci sono la ex miniera di Pasquasia (documentata agli <i>atti parlamentari, p. 541, XVII legislatura, allegato B ai resoconti</i>) e l'indagine storica sulla Torre Ottagonale di Enna.
          </Col>
        </Bio>
      </Container.Fixed>
      <Container.Fixed className={"py-2 pb-lg-2 mb-4"} style={{
        backgroundColor: "#fff",
        borderRadius: "8px"
      }}>
        <Bio image="UmbertoMassocco.jpg" alt="Umberto Massocco" mdxType="Bio">
          <Col mdxType="Col">
            <div className={"pb-1 h1"} style={"font-size: 20px!important"}><b>Umberto Massocco (Palermitano)</b></div>
            <div className={"pb-2 h1"}><b>(n. 1903, m. 1985)</b></div>
            <b><i>Colonnello del Genio Civile e Militare ed Ispettore Onorario alle Antichità di 52 Comuni Siciliani.</i></b> I suoi studi hanno permesso intorno agli anni ‘60 di individuare nella Torre Ottagonale di Enna l’<i>Umbilicus Siciliae</i>, ossia il centro del sistema dei cardi e del decumano costituenti gli allineamenti topografici lungo i quali si sviluppò la rete stradale della Sicilia antica.
          </Col>
        </Bio>
      </Container.Fixed>
    </Container.Fluid>
    <Container.Fixed id="mappe" className="py-3 pb-lg-3">
      <Section.One>
        <Title type={"h3"} mdxType="Title">Schema Decumano e Cardini</Title>
        <hr></hr>
        <iframe title="Schema Decumano e Cardini" src="https://www.google.com/maps/d/embed?mid=11pC4XJMgCNjUtakDSGJZxbdXKuyk4Tfr" width="100%" height="480"></iframe>
      </Section.One>
      <Section.Two className="py-3 py-lg-0">
        <Title type={"h3"} mdxType="Title">Allineamento al solstizio d'estate</Title>
        <hr></hr>
        <iframe title="Allineamento al solstizio d'estate" src="https://www.google.com/maps/d/embed?mid=1TMUKhQn1GnLAWX-cQO-RG_Akqeffw-hA" width="100%" height="480"></iframe>
      </Section.Two>
    </Container.Fixed>
    <Container.Fluid id="gallery">
      <Section.FullWidth>
        <ImageGallery mdxType="ImageGallery" />
      </Section.FullWidth>
    </Container.Fluid>
    <Container.Fluid id="introduzione" className={"pt-4 pb-2"} background={"#ebe7db"} color={"#2c2e31"}>
      <Section.One className={"pr-lg-0"}>
        <iframe title="Torre di Enna" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/5sxYZLkAhyk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Section.One>
      <Section.Two className={"py-3 py-lg-0"}>
        <Title type={"h3"} mdxType="Title">Nella città di Enna il capolavoro dell'arte Astronomica e Gromatica dei Siculi</Title>
        <hr></hr>
        <p>{`Il dibattito in merito all'origine e funzione primaria della Torre di Enna, comunemente detta "Torre di  Federico II di Svevia",
si è acceso con la pubblicazione del libro `}<strong parentName="p">{`“La verità sulla Torre ottagonale di Enna”`}</strong>{` (2018) di `}<strong parentName="p">{`Angelo Severino`}</strong>{`.`}</p>
        <p>{`Angelo Severino, partendo dalle indagini condotte intorno agli anni ’50 dal Colonnello Umberto Massocco e con l’ausilio di documenti
storici inediti, sostiene l'ipotesi secondo cui la fondazione della Torre ottagonale, modificata in età medievale, possa risalire ai
Siculi intorno all’Undicesimo secolo a.C., qualificando il monumento come il `}<strong parentName="p">{`primo (e forse l’ultimo superstite) osservatorio
astronomico-geodetico del Mediterraneo`}</strong>{`.`}</p>
      </Section.Two>
    </Container.Fluid>
    <Container.Fixed id="intervista" className={"pt-4 pb-2"}>
      <Section.One>
        <Title type={"h3"} mdxType="Title">Intervista inedita ad Angelo Severino</Title>
        <hr></hr>
        <blockquote>
          <ul parentName="blockquote">
            <li parentName="ul">
              <p parentName="li">{`Alla scoperta della Torre ottagonale di Enna`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Aspetti di astronomia a cura dell'ing. Liborio Petralia`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`L'archivio inedito dei documenti dell'autore`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Incremento turistico e culturale in Enna`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`Conclusione e critiche finali`}</p>
            </li>
          </ul>
        </blockquote>
      </Section.One>
      <Section.Two order={"first"}>
        <iframe title="Intervista inedita ad Angelo Severino" width="100%" height="315" src="https://www.youtube-nocookie.com/embed/taRQSYXxow8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Section.Two>
    </Container.Fixed>
    <Container.Fluid id="collana" background="#444" color="#eee" className={"py-5"} style={{
      boxShadow: "inset 0 10px 10px -10px rgba(0,0,0,0.3)",
      letterSpacing: ".02em"
    }}>
      <Section.One col={"7"} order={"last"} className={"pb-3 pb-lg-0"}>
        <Title type={"h3"} style={{
          fontWeight: "500",
          fontSize: "215%",
          lineHeight: "120%",
          letterSpacing: "initial",
          color: "#f2ba4d"
        }} mdxType="Title">
Progetto <span style={{
            fontWeight: "800"
          }}>Collana Libri Storie di Sicilia</span>
        </Title>
        <hr></hr>
        <p><strong parentName="p">{`COLLANA LIBRI STORIE DI SICILIA`}</strong>{` è un progetto nato a Enna, luogo in cui si trova la famosa Torre ottagonale Hennaion (Umbilicus Siciliae et Trinakie)
ossia la costruzione più antica del mondo realizzata con finalità astronomiche e geodetiche che rappresenta il punto centrale del templum celeste di Sicilia.`}</p>
        <p>{`Il progetto è dedicata a tutti quelli che provano vero amore e profondo rispetto nei confronti della Sicilia.`}</p>
        <p>{`Si promuovono in maniera del tutto gratuita libri (anche auto-pubblicati), tesi di laurea, ricerche storiche e scientifiche, eventi culturali e pubblicazioni
sulla storia della Sicilia antica e recente.`}</p>
        <p>{`La pagina è un punto di riferimento per una vera comunità di ricerca libera e indipendente (non solo virtuale), finalizzata al bene del Popolo Siciliano.`}</p>
        <p>{`La nostra coesione sociale si basa sulla fiducia del lavoro di squadra, e sui valori di una vera famiglia composta da ricercatori liberi e indipendenti,
attivisti siciliani, poeti e scrittori sinceri.`}</p>
        <p>{`Con il vostro aiuto, noi ci impegniamo ogni giorno per costruire sinergia tra persone e idee, con l’obiettivo di riscrivere la vera storia della Sicilia
e far conoscere finalmente a tutto il mondo il suo reale valore storico-culturale.`}</p>
        <p>{`Crediamo che la cultura e la ricerca libera possa creare economia e benessere per la nostra bellissima Isola.`}</p>
        <p>{`Tu cosa ne pensi di questo progetto?`}</p>
      </Section.One>
      <Section.Two col={"5"} className="font-size-xsmall">
        <div style={{
          textAlign: "center"
        }}>
          <iframe title="Collana Libri Storie di Sicilia su Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcollana.libri.storie.sicilia%2F&tabs&width=290&height=181&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="290" height="100%" style={{
            "border": "none",
            "overflow": "hidden"
          }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
        <Citazione image="foto_es.png" imageAlt="Enrico Sinicropi" mdxType="Citazione">
          <p>{`"Se nemici esistono contro Enna, rivolgo loro la preghiera
di non irridersi, almeno,
della scienza, dell'arte e della vita, che le diedero il volto immortale della bellezza e della eternità"`}</p>
          <p><em parentName="p">{`Enrico Sinicropi, Enna nella storia, nell'arte e nella vita, Arti grafiche Antonio Renna, Palermo 1958, p.108`}</em></p>
        </Citazione>
        <hr></hr>
        <Citazione image="foto_mk.png" imageAlt="Milan Kundera" mdxType="Citazione">
          <p>{`"Per liquidare i Popoli si comincia con il privarli della memoria.
Si distruggono i loro libri, la loro cultura, la loro storia. E qualcun altro scrive loro altri libri, li fornisce di un'altra cultura,
inventa per loro un'altra storia. Dopo di che il Popolo incomincia lentamente a dimenticare quello che è e quello che è stato.
E il mondo attorno a lui lo dimentica ancora più in fretta"`}</p>
          <p><em parentName="p">{`Milan Kundera`}</em></p>
        </Citazione>
      </Section.Two>
    </Container.Fluid>
    <Footer id="contatti" background={"#b6bbbc"} color={"#2c2e31"} mdxType="Footer">
      <Section.FullWidth className={"h4"}>
        <h3>{`Per informazioni, contatta il Project Manager`}</h3>
        <h4>{`Fabrizio Natanaele Severino`}</h4>
        <h4><a parentName="h4" {...{
            "href": "mailto:fabri8bit@gmail.com"
          }}>{`fabri8bit@gmail.com`}</a></h4>
      </Section.FullWidth>
      <Section.FullWidth>
        <hr></hr>
        <Copyright from="2017" mdxType="Copyright" />
        <p>{`Progettazione e Sviluppo `}<Anchor external href="https://www.zenitsecurity.com" title="Vai al sito di Zenit Security" mdxType="Anchor">{`Zenit Security`}</Anchor></p>
        <hr></hr>
        <p>{`Versione sito 2.2 - ultimo aggiornamento il 27 gennaio 2025`}</p>
        <Anchor href="https://torredienna.it/extra/PrivacyPolicy.pdf" title={"Scarica il PDF con la Privacy Policy"} mdxType="Anchor">Privacy e Cookie Policy</Anchor>
        <Patamu mdxType="Patamu" />
      </Section.FullWidth>
    </Footer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      